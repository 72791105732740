import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/all_allowed/HomePage/home";
import SignInComponent from "./pages/all_allowed/SignInInstructions/SignIn";
import PrivateRoutes from "./routes/private_routes/PrivateRoutes";
import Header from "./components/common/Header/header";
import Head from "./components/common/Head/head";
import Footer from "./components/common/Footer/footer";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import Pricing from "./pages/all_allowed/Pricing/Pricing";
import AffiliateProgram from "./pages/user_only/affiliate/AffiliateProgram";
import AffiliateForm from "./pages/user_only/affiliate/AffiliateForm";
import AffiliateSuccess from "./pages/user_only/affiliate/AffiliateSuccess";
import SendSmsUsingGSheets from "./pages/all_allowed/guides/SendSmsUsingGSheets";
import Contact from "./pages/user_only/contact/contact";
import SendSmsUsingAirtable from "./pages/all_allowed/guides/SendSmsUsingAirtable";
import SendEmailsUsingAirtable from "./pages/all_allowed/guides/SendEmailsUsingAirtable";
import SendEmailsUsingGSheets from "./pages/all_allowed/guides/SendEmailsUsingGSheets";
import Guides from "./pages/all_allowed/guides";
import PrivacyPolicy from "./pages/all_allowed/guides/PrivacyPolicy";
import TermsOfService from "./pages/all_allowed/guides/TermsOfService";
import LoginComponent from "./pages/sheets_extension/Login";
import { NewCampaignProvider } from "./contexts/NewCampaignContext";
import ActionConfig from "./pages/sheets_extension/ActionConfig/ActionConfigExtension";
import { SheetsExtensionProvider } from "./pages/sheets_extension/ActionConfig/SheetsExtensionContext";
import TrialEnded from "./pages/all_allowed/SignInInstructions/TrialEnded";
import QuotaExceededCard from "./pages/all_allowed/SignInInstructions/OutOfEmails";
import { useNavigate } from "react-router-dom";
import ModalSelectedEmail from "./pages/sheets_extension/ActionConfig/Email/ModalSelectedEmail";
import ActionSelectorExtension from "./pages/sheets_extension/SelectAction";
import TwilioActionConfig from "./pages/sheets_extension/ActionConfig/ActionSmsConfigExtension";
import JobDetailsMainExtension from "./pages/sheets_extension/details";
import { JobDetailsProviderExtension } from "./pages/sheets_extension/details/DetailsExtensionContext";
import ProtectedRoute from "./routes/private_routes/ProtectedExtensionRoute";
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(null);
  const [uid, setUid] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState({})
  const  [expiredData, setExpiredData] = useState(true)
  const location = useLocation(); // Get the current path
  const navigate = useNavigate();
  const verifyToken = async () => {
    const url = 'https://postsheetapp.com/api/check-auth';
    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Falha na autenticação: ${errorData.detail || 'Erro desconhecido'}`);
      }

      const data = await response.json();

      setIsAuthenticated(data.isAuthenticated);
      setId(data.encrypted_id);
      setUid(data.encrypted_uid);
      setSubscriptionData(data.data);
      if(!data.data.can_still_run) 
      {
        navigate('/exceeded');
      }
      if(data.data.expired) 
        {
          navigate('/expired');
        }
      setExpiredData(data.data.expired)
    } catch (error) {
      console.error('Erro ao verificar a autenticação:', error.message);
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    verifyToken().finally(() => setLoading(false));
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        {/* Only render Header and Footer if not on /login-extension */}
        {!(
  ["/login-extension", "/action-config-extension", "/action-config-sms-extension", "/detailed-preview-extension", "/action-selector-extension"]
    .includes(location.pathname) || location.pathname.startsWith("/extension/details")
) && (
  <>
    <Header isAuthenticated={isAuthenticated} loading={loading} />
    <Head />
  </>
)}
        <Routes>
        <Route
                    path="/expired"
                    element={

                        <TrialEnded />
                    }
                  />
          <Route path="/login-extension" element={<SheetsExtensionProvider><LoginComponent /></SheetsExtensionProvider>} />
      <Route
        path="/detailed-preview-extension"
        element={
          <SheetsExtensionProvider>
            <ProtectedRoute>
              <ModalSelectedEmail />
            </ProtectedRoute>
          </SheetsExtensionProvider>
        }
      />
      <Route
        path="/action-selector-extension"
        element={
          <SheetsExtensionProvider>
              <ActionSelectorExtension />
          </SheetsExtensionProvider>
        }
      />
      <Route
        path="/action-config-extension"
        element={
          <SheetsExtensionProvider>
            <ProtectedRoute>
              <ActionConfig />
            </ProtectedRoute>
          </SheetsExtensionProvider>
        }
      />
      <Route
        path="/twilio-action-config-extension"
        element={
          <SheetsExtensionProvider>
            <ProtectedRoute>
              <TwilioActionConfig />
            </ProtectedRoute>
          </SheetsExtensionProvider>
        }
      />
      <Route
        path="/extension/details/:uid"
        element={
          <SheetsExtensionProvider>
          <JobDetailsProviderExtension>
            <ProtectedRoute>
              <JobDetailsMainExtension />
            </ProtectedRoute>
          </JobDetailsProviderExtension>
          </SheetsExtensionProvider>
        }
      />

          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/signin" element={<SignInComponent />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/affiliate-program" element={<AffiliateProgram />} />
          <Route path="/affiliate/signup" element={<AffiliateForm />} />
          <Route path="/affiliate/success" element={<AffiliateSuccess />} />
          <Route path="/guides/sendsmsgsheets" element={<SendSmsUsingGSheets />} />
          <Route path="/guides/sendsmsairtable" element={<SendSmsUsingAirtable />} />
          <Route path="/guides/sendemailsairtable" element={<SendEmailsUsingAirtable />} />
          <Route path="/guides/sendemailsgsheets" element={<SendEmailsUsingGSheets />} />
          <Route
                    path="/contact"
                    element={

                        <Contact />
                    }
                  />
          <Route path="/guides" element={<Guides />} />
        </Routes>

        <Routes>
          <Route path="/app/*" element={<PrivateRoutes />} />
        </Routes>


        {!(
  ["/login-extension", "/action-config-extension", "/action-config-sms-extension", "/detailed-preview-extension", "/action-selector-extension"]
    .includes(location.pathname) || location.pathname.startsWith("/extension/details")
) && (
  <>
    <Footer/>
  </>
)}

      </ThemeProvider>
    </>
  );
}

export default App;
